import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import Title from '@/components/Title';
import LayoutMain from '@/components/Layouts/LayoutMain';

export default function Overview() {
  return (
    <LayoutMain>
      <Title>Visions</Title>

      <ul className="flex flex-wrap -mx-4">
        {[
          {
            id: 1,
            title: `Miau MiauMiauMiau Miau MiauMiauMiauMiauMiauMiau Miau MiauMiau Miau`,
          },
          { id: 2, title: `Miau` },
          { id: 3, title: `Miau` },
          { id: 4, title: `Miau` },
          { id: 5, title: `Miau` },
          { id: 6, title: `Miau` },
        ].map((item: any) => (
          <li key={item.id} className="block px-4 mb-8 basis-2/4 lg:basis-1/3">
            <Link className="relative block" to="/details">
              <FontAwesomeIcon
                className="absolute text-white top-4 right-4"
                icon={faFolder}
              />
              <img
                src="https://loremflickr.com/600/600
   "
                alt="placeholder"
              />
              <div className="absolute bottom-0 block w-full px-2 pb-2 text-center">
                <span className="px-1 bg-white opacity-70">{item.title}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </LayoutMain>
  );
}
